ngApp.controller('RowController', ['$scope', '$http', '$attrs', '$log', function ($scope, $http, $attrs, $log) {

    var that = this;
    $scope.onday = $attrs.onday *1;

    $scope.updateServer = function (id, key, val) {

        $http.put($attrs.updateroute, {'id': id, 'key': key, 'val': val})
            .success(
                function (response) {
                    noty({
                        text: '<strong>Successfuly updated!</strong>',
                        layout: 'topRight',
                        type: 'success',
                        timeout: 100
                    });
                }
            ).error(
            function (response) {
                $log.log(response);
                noty({
                    text: '<strong>Failed to update!</strong>',
                    layout: 'topRight',
                    type: 'error',
                    timeout: 15000
                });

            }
        );
    };

    $scope.deleteRow = function (el, url) {
        
        $(el).remove();

        $http.delete(url)
            .success(
                function (response) {
                    noty({
                        text: '<strong>Successfuly deleted!</strong>',
                        layout: 'topRight',
                        type: 'success',
                        timeout: 100
                    });
                }
            )
            .error(
                function (response) {
                    noty({
                        text: '<strong>Failed to delete!</strong>',
                        layout: 'topRight',
                        type: 'error',
                        timeout: 15000
                    });

                }
            );
    };

}]);