ngApp.factory('$storage', function ($rootScope, $log) {

  var service = {

    model: {},

    put: function (key, val) {
      key = service.makeKey(key);
      service.model[key] = val;
    },
    get: function (key) {
      key = service.makeKey(key);
      return (service.exists(key)) ? service.model[key] : false;
    },
    pull: function (key) {
      var val = service.get(key);
      service.delete(key);
      return val;
    },
    delete: function (key) {
      key = service.makeKey(key);
      if(service.exists(key)){
        delete service.model[key];
      }
    },

    has: function (key) {
      key = service.makeKey(key);
      if(typeof service.model[key] == 'undefined'){
        return false;
      }
      return true;
    },

    /*--------------------------------------------------------------------------
     * Internal / Private
     * 2016-02-16
     *///-----------------------------------------------------------------------

    exists: function (key) {
      if(typeof service.model[key] == 'undefined'){
        return false;
      }
      return true;
    },

    makeKey: function (seed) {
      return seed.split("").reduce(function (a, b) {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a
      }, 0);
    },

    saveModel: function () {
      sessionStorage.$storage = angular.toJson(service.model);
    },

    restoreModel: function () {
      if(typeof sessionStorage.$storage !== 'undefined'){
        service.model = angular.fromJson(sessionStorage.$storage);
      }

      $rootScope.$broadcast('storage:ready');
    }

  };

  $rootScope.$on("session:starting", service.restoreModel);
  $rootScope.$on("session:ending", service.saveModel);

  return service;
});