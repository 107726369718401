ngApp.controller('FileUploadController', ['$scope', '$log', '$attrs', '$http', 'Upload', '$timeout', function ($scope, $log, $attrs, $http, Upload, $timeout) {

  var that = this;

  $scope.status = 'idle';
  $scope.progressPercentage = 0;

  $scope.$watch('files', function () {
    $scope.upload($scope.files);
  });
  $scope.$watch('file', function () {
    if($scope.file != null){
      $scope.files = [$scope.file];
    }
  });

  $scope.upload = function (files) {

    if(files && files.length){
      $scope.status = 'working';

      for(var i = 0; i < files.length; i ++){
        var file = files[i];
        if(! file.$error){
          bootStrapAlert("<h3>Sending file!</h3> <p><b>Don't leave</b> this page till it's completely done.  I'll tell you when!</p>");

          Upload.upload({
            url: $attrs.route,
            data: {
              //anything: $scope.anything,
              _method: 'PUT',
              file: file
            }
          }).progress(function (evt) {
            $scope.progressPercentage = ($scope.status != 'idle') ? parseInt(100.0 * evt.loaded / evt.total) : 0;
            $scope.log = 'progress: ' + $scope.progressPercentage + '% ' +
              evt.config.data.file.name + '\n' + $scope.log;
          }).success(function (data, status, headers, config) {
            $scope.status = 'idle';
            $scope.progressPercentage = 0;

            $($attrs.fieldSelector).val(data.file_path);
            $($attrs.fieldSelector).trigger("change");

            bootStrapAlert('<strong>Done sending:<br/>' + data.file_path + '</strong>', 'success');

          }).error(function (data, status, headers, config) {

            $scope.status = 'idle';
            $scope.progressPercentage = 0;

            var msg = 'Failed to upload!';
            if(typeof data.message != 'undefined'){
              msg = '<h1>' + msg + '</h1><p>'+ data.message +'</p>'
            }

            if(typeof data.errors != 'undefined'){
              for(var input_name in data.errors){
                var input_errors = data.errors[input_name];
                for(var i = 0; i < input_errors.length; i++){
                  msg = msg + '<div>'+ input_errors[i] +'</div>'
                }
              }
            }

            bootStrapAlert(msg, 'danger', {timeout: 2000});


          }).abort(function (data, status, headers, config) {
            $scope.status = 'idle';
            $scope.progressPercentage = 0;
          });
        }
      }
    }
  };
}]);