ngApp.controller('SparkController', ['$scope', '$element', '$http', '$attrs', '$storage', '$log', function ($scope, $element, $http, $attrs, $storage, $log) {
  var that = this;

  $scope.loaded = false;
  var spark = $($element).find('.spark');
  var int = $($element).find('.spark-int');

  var display = {
    //width: '80',
    height: '27',
    tooltipFormat: '{{value:levels}} - {{value}}'
  };

  if($attrs.graphStyle == 'line'){
    display.type = 'line';
    display.lineColor = '#0ca5e7';
    display.fillColor = '#e5f3f9';
  }else{
    display.type = 'bar';
    display.barColor = '#0ca5e7';
  }

  spark.bind('sparklineRegionChange', function (ev) {
    var sparkline = ev.sparklines[0];
    var region = sparkline.getCurrentRegionFields();
    if($.isArray(region)){
      region = region[0];
    }
    var value = (typeof region.value != 'undefined') ? region.value : region.y;
    int.html(value);
  }).bind('mouseleave', function () {
    int.html('&nbsp;');
  });

  this.loadData = function () {
    $http.get($attrs.route, {cache: false})
      .then(function (response) {
        var data = response.data;
        spark.sparkline(data, display);
        $scope.loaded = true;
        $storage.put($attrs.route, data);
      })
      .catch(function (response) {
        $log.log(response);
      });
  };

  $scope.$on('storage:ready', function () {
    if($storage.has($attrs.route)){
      var data = $storage.get($attrs.route);
      spark.sparkline(data, display);
      $scope.loaded = true;
    }

    that.loadData();
    setInterval(that.loadData, 5000);
  });

}]);