ngApp.controller('BadgeController', ['$scope', '$http', '$attrs', '$storage', '$log', function ($scope, $http, $attrs, $storage, $log) {
  var that = this;
  $scope.badgeVal = false;

  this.loadData = function () {
    $http.get($attrs.route, {cache: false})
      .then(function (response) {
        if(response.status == 200){
          var data = response.data;

          if(typeof data.badgeVal != 'undefined'){
            $scope.badgeVal = data.badgeVal;
            $storage.put($attrs.route, $scope.badgeVal);
          }

        }
      }).catch(function (response) {
      $log.log(response)
    });
  };

  $scope.$on('storage:ready', function () {
    if($storage.has($attrs.route)){
      $scope.badgeVal = $storage.get($attrs.route);
    }

    that.loadData();
    setInterval(that.loadData, 30000);
  });

}]);