ngApp.filter('startFrom', function () {
    return function (input, start) {
        start = +start; //parse to int
        return input.slice(start);
    }
});


ngApp.filter('reduceTo', function () {
    return function (obj, displayCols) {
        newObj = {};
        for(var p in displayCols){
            p = displayCols[p];
            newObj[p]=  obj[p];
        }
        return newObj;
    }
});