ngApp.factory('$socket', function ($rootScope, $log) {
  var service = {

    model: {},

    openConnection: function () {
      $log.log('Socket opening...')

      // service.model.socket = new io(':3000');
      //
      // service.model.socket.on('connect', function () {
      //   $rootScope.$broadcast("socket:ready");
      // });
      //
      // service.model.socket.on('disconnect', function () {
      //   $log.log('Socket disconnected.');
      // });

      $log.log('socket:echo')
      service.model.socket = new Echo({
        broadcaster: 'socket.io',
        host: SOCKET_SERVER
      });

      //
      service.model.socket.channel('Public.Notifications')
        .listen('Public.Notifications', function (e) {
          console.log(e);
          bootStrapAlert(e.msg, e.class);
        });

      if(typeof User == 'object'){
        $log.log('socket:user acquired with id:' + User.id)

        service.model.socket.private('User.Notifications.' + User.id)
          .notification(function (n) {
            console.log(n);
            bootStrapAlert(n.msg, n.class);
          });
      }

    },

    closeConnection: function () {
    }

  };

  if(typeof io != 'undefined'){
    $rootScope.$on("session:starting", service.openConnection);
    $rootScope.$on("session:ending", service.closeConnection);
  }else{
    $log.log('Missing socket.io js.');
  }

  return service;
});
