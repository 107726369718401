//$scope.$emit up, $scope.$broadcast down

var ngApp = angular.module('adminPanel', ['ngAnimate', 'ui.bootstrap', 'ngFileUpload', 'ngSanitize', 'ngCsv']);

ngApp.run(function ($rootScope, $log) {

    $(document).ready(function (event) {
        $rootScope.$broadcast('session:starting');
    });

    window.onbeforeunload = function (event) {
        $rootScope.$broadcast('session:ending');
    };

});