ngApp.controller('VarController', ['$scope', '$attrs', '$http', '$storage', '$log', function ($scope, $attrs, $http, $storage, $log) {

  if(typeof $attrs.val != 'undefined'){
    $scope.val = $attrs.val;
  }else{
    $scope.val = '';
  }


  this.loadData = function () {
    //$log.log('loading');
    $scope.loaded = false;
    $http.get($attrs.route, {cache: false})
      .success(function (data) {
        $log.log('success');
        $scope.val = data.val;
        $scope.loaded = true;
        $storage.put($attrs.route, data);
      })
      .error(function (response) {
        //$log.log('error');
      });
  };

  $scope.$on('storage:ready', function () {
    if(typeof $attrs.route != 'undefined'){
      if(! $storage.has($attrs.route)){
        return;
      }

      var data = $storage.get($attrs.route);
      if(typeof data == 'object'){
        $scope.val = data.val;
        $scope.loaded = true;
        $scope.$apply();
      }
    }
  });

  $scope.asSlug = function () {

    return $scope.val
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/&/g, '-and-')         // Replace & with 'and'
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/-$/, '');
  }

  if(typeof $attrs.route != 'undefined'){
    this.loadData();
  }else{
    $scope.loaded = true;
  }
}]);